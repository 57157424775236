import {
  MetalConfig,
  MetalName,
  SteelStainless,
  MaterialEndurance,
  EdgeRetention,
} from '~src/types/knife'

const knife: MetalConfig = {
  id: '2',
  name: MetalName.K110,
  title: { PL: 'stal K110', EN: 'K110 steel' },
  description: {
    PL: [
      'Austriacka stal narzędziowa ( nie jest to stal nierdzewna ), o bardzo dużej odporności na ścieranie (trudna do ostrzenia), osiąga bardzo wysokie twardości, bardzo dobrze trzyma krawędzie tnące. Jeśli zaakceptuje się patynę - powłokę z\u00A0tlenków na powierzchni ostrza - i\u00A0trochę bardziej wymagające ostrzenie, to jest to jedna z\u00A0najlepszych stali na ostrza noży.',
    ],
    EN: [
      'Austrian tool steel (not stainless) with very high resistance to abrasion (difficult to sharpen), can be really hard and holds cutting edges very well. If you don’t mind patina – a\u00A0layer of oxides on the surface of the blade – and a\u00A0little more demanding sharpening, then this is one of the best steel types for knife blades.',
    ],
  },
  price: {
    PL: '+ 100 zł',
    EN: '+ 25 €',
  },
  priceRow: {
    PL: 100,
    EN: 25,
  },
  img: 'K110',
  stainless: SteelStainless.two,
  sharpening: {
    PL: 'trudne',
    EN: 'hard',
  },
  hardness: MaterialEndurance.four,
  edgeRetention: EdgeRetention.three,
}

export default knife
