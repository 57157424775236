import {
  MetalConfig,
  MetalName,
  SteelStainless,
  MaterialEndurance,
  EdgeRetention,
} from '~src/types/knife'

const knife: MetalConfig = {
  id: '3',
  name: MetalName.M398,
  title: { PL: 'stal M398', EN: 'M398 steel' },
  description: {
    PL: [
      'M398 - to nowy gatunek\u00A0na rynku stali narzędziowych, produkowany od 2019 roku - nowa stal\u00A0z serii MICROCLEAN jest rozwinięciem dobrze znanej\u00A0z topowych linii noży, stali M390. Do składu stopowego dodano jeszcze więcej Węgla\u00A0i Wanadu (7,2%), odpowiedzialnych\u00A0za powstawanie bardzo twardych węglików. Obecnie M398 to najdłużej utrzymująca ostrość stal nierdzewna na rynku\u00A0(!). Bardzo duża ilość węglików przekłada się na jej nieznacznie większą podatność na wykruszenia krawędzi\u00A0w porównaniu\u00A0ze ‘starszą siostrą’ - M390. Niemniej za odrobinę uważności ze strony użytkownika, odwdzięczy się rewelacyjną ostrością\u00A0i bezkonkurencyjnie długimi okresami sprawnej pracy bez konieczności ostrzenia.',
      'Ze stali proszkowych wykonujemy tylko ostrza do noży, które są używane najczęściej - noże szefa kuchni 210, 240mm oraz Santoku.',
    ],
    EN: [
      'M398 - Produced since 2019, M398 MICROCLEAN series\u00A0is a newcomer in the tool steels market. This steel grade\u00A0is based on\u00A0a well known ‘high-end’ knife blade steel M390. Higher amounts of Carbon and Vanadium(7,2 %) in the alloy lead to higher carbide content which\u00A0is responsible for\u00A0a crazy abrasion resistance. Due to that M398 has the highest edge retention among all the stainless steels available on the market. (!) Large amounts of carbides\u00A0in the steel matrix lead to slight decrease\u00A0in toughness in comparison to the ‘older sister’ - M390, which makes the cutting edge more vulnerable. Nevertheless, for additional care and attention\u00A0it will pay back with excellent sharpness and exceptionally long periods of pleasurable work without sharpening.',
      'Currently we use Powder Steel alloys only for production\u00A0of the most heavily used types of blades - chefs knives 210 and 240mm and Santoku.',
    ],
  },
  price: {
    PL: '+ 650 zł',
    EN: '+ 145 €',
  },
  priceRow: {
    PL: 650,
    EN: 145,
  },
  img: 'M398',
  stainless: SteelStainless.five,
  sharpening: {
    PL: 'trudne',
    EN: 'hard',
  },
  hardness: MaterialEndurance.four,
  edgeRetention: EdgeRetention.five,
}

export default knife
