import {
  MetalConfig,
  MetalName,
  SteelStainless,
  MaterialEndurance,
  EdgeRetention,
} from '~src/types/knife'

const knife: MetalConfig = {
  id: '4',
  name: MetalName.Damasteel,
  title: { PL: 'Damasteel', EN: 'Damasteel' },
  description: {
    PL: [
      "Damasteel to rodzina stali zgrzewanych, produkowanych przez Szwedzką firmę Damasteel\u00A0AB. Charakterystyczne wzory, które widać\u00A0na ostrzach powstają przez wielokrotne zgrzewanie gatunków stali\u00A0o różnych składach stopowych. Damast, którego używamy to materiał powstały\u00A0z dwóch wysokiej jakości, nierdzewnych stali proszkowych\u00A0RWL 34\u00A0i PMC 27. Wzór, którego używamy\u00A0to\u00A0tzw. ‘twist’, który powstaje poprzez skręcenie kutego warstwowo pręta, co nadaje ostrzom charakterystyczny skośny układ 'słojów'.",
      'To jeden z najlepszych, ale też najdroższych materiałów,\u00A0z których można zrobić ostrze. Damasteel\u00A0to materiał dla poszukiwaczy nietypowego wzornictwa połączonego\u00A0ze świetną jakością.',
      'Z materiału Damasteel wykonujemy tylko duże ostrza uniwersalnego zastosowania: Noże szefa kuchni 210\u00A0i 240mm oraz Santoku. Zwykle przygotowujemy jedną lub dwie serie z wzorzystych stali rocznie. Odezwij się do nas żeby potwierdzić dostępność konkretnego typu ostrza.',
    ],
    EN: [
      'it’s\u00A0a trademark of\u00A0a group\u00A0of high quality tool steels produced by Swedish company Damasteel AB. Very characteristic patterns that can be seen\u00A0on Damasteel blades are achieved\u00A0by multiple hot welding\u00A0of steels with different alloy compositions. The specific steel that we use\u00A0is made of two high quality, stainless powder steels - RWL 34 and PMC 27. The pattern of our choice\u00A0is one of, so called ‘twists’, which are made by twisting\u00A0a laminated bar around its axis.',
      'It is one the most precious and most expensive materials used for blademaking. If you are going for\u00A0an unusual look and great blade performance, Damasteel is more than worth considering! :)',
      'Damsteel\u00A0is available only for big blades which\u00A0are used most often, such as Chefs’ knives 210-240mm and Santoku blades. We usually make one\u00A0or two series of patterned steel blades a year - call us to check the actual availability of specific blades.',
    ],
  },
  price: {
    PL: '+ 1800 - 2500 zł',
    EN: '+ 400 - 550 €',
  },
  priceRow: {
    PL: 1800,
    EN: 400,
  },
  img: 'damasteel',
  stainless: SteelStainless.five,
  sharpening: {
    PL: 'łatwe',
    EN: 'easy',
  },
  hardness: MaterialEndurance.three,
  edgeRetention: EdgeRetention.three,
}

export default knife
