import { WoodConfig, WoodName, MaterialEndurance } from '~src/types/knife'

const wood: WoodConfig = {
  id: '3',
  name: WoodName.Heban,
  title: { PL: 'Heban', EN: 'Ebony' },
  description: {
    PL: [
      'Heban to tworzywo rzeźbiarzy.  Od wieków kojarzony jest ze sztuką, jednak jako materiał bardzo dobrze nadaje się również do zastosowań w\u00A0rzemiośle i\u00A0lutnictwie. Do naszych noży używamy odmiany afrykańskiego hebanu pochodzącego z\u00A0Mozambiku, o charakterystycznym rysunku jaśniejszych słojów. Obróbka hebanu wymaga doświadczenia i\u00A0bardzo ostrych narzędzi. Jest to bardzo twarde, gęste drewno.  Heban charakteryzuje się też bardzo wysoką odpornością na działanie wody.',
    ],
    EN: [
      'Ebony is the material of sculptors. For ages it has been associated with art, but as a material, it also finds its applications in\u00A0craft and lutherie. For our knives we use African ebony from Mozambique with characteristic fairer grains. Processing of ebony requires experience and very sharp tools as the wood is extremely hard and dense. It is also highly resistant to water.',
    ],
  },
  price: {
    PL: '',
    EN: '',
  },
  priceRow: {
    PL: 0,
    EN: 0,
  },
  care: {
    PL:
      'Heban jest naturalnie oleistym gatunkiem o zwartej strukturze. Niemniej warto co pewien czas przetrzeć rękojeści olejem ( np. tungowym, lnianym ) dla wydobycia głębszych kontrastów i\u00A0dodatkowej ochrony przed wodą.',
    EN:
      'Ebony is naturally oily species with a tight structure. It is, however, worth to wipe the handle with linseed or tung oil every now and then to bring out deeper contrasts and provide additional protection from water.',
  },
  oilCare: {
    PL: 'co 2-3 miesiące.',
    EN: 'every 2-3 months.',
  },
  endurance: MaterialEndurance.five,
  weight: {
    PL: 'średnio ciężkie',
    EN: 'middle',
  },
  img: 'heban',
}

export default wood
