import { WoodConfig } from '~src/types/knife'

import Heban from './_Heban.wood'
import Ironwood from './_Ironwood.wood'
import Juglans from './_Juglans.wood'
import Olea from './_Olea.wood'
import Painted from './_Painted.wood'
import Snakewood from './_Snakewood.wood'

export default [
  Heban,
  Juglans,
  Olea,
  Painted,
  Ironwood,
  Snakewood,
] as WoodConfig[]
