import { MetalConfig } from '~src/types/knife'

import K110 from './_K110.metal'
import M398 from './_M398.metal'
import M390 from './_M390.metal'
import N690 from './_N690.metal'
import SanMai from './_SanMai.metal'
import Damasteel from './_Damasteel.metal'

export default [N690, K110, M390, M398, SanMai, Damasteel] as MetalConfig[]
