import {
  MetalConfig,
  MetalName,
  SteelStainless,
  MaterialEndurance,
  EdgeRetention,
} from '~src/types/knife'

const knife: MetalConfig = {
  id: '1',
  name: MetalName.N690,
  title: { PL: 'stal N690', EN: 'N690 steel' },
  description: {
    PL: [
      'Austriacka narzędziowa stal nierdzewna, ma dobrą odporność na korozję, wysoko się hartuje, jest dość łatwa do ostrzenia i\u00A0dobrze trzyma krawędzie tnące. Ma dodatki stopowe chromu, kobaltu i\u00A0wanadu, które są odpowiedzialne za powstawanie twardych węglików. Węgliki poprawiają agresywność cięcia i\u00A0zwiększają odporność stali na ścieranie. N690 to porządna stal, dająca bardzo dobre właściwości tnące, a\u00A0jednocześnie łatwa w\u00A0codziennym użytku.',
    ],
    EN: [
      'Austrian stainless tool steel, resistant to corrosion, with high hardening properties. It is quite easy to sharpen and it holds cutting edges well. Contains chrome, cobalt and vanadium alloys that are responsible for creating hard carbides. Carbides improve the aggressiveness of cutting and abrasion resistance. N690 is a\u00A0solid steel with very good cutting properties and at the same time is easy in everyday use.',
    ],
  },
  price: {
    PL: '',
    EN: '',
  },
  priceRow: {
    PL: 0,
    EN: 0,
  },
  img: 'N690',
  stainless: SteelStainless.four,
  sharpening: {
    PL: 'średnio trudne',
    EN: 'middle',
  },
  hardness: MaterialEndurance.three,
  edgeRetention: EdgeRetention.three,
}

export default knife
