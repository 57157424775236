import { WoodConfig, WoodName, MaterialEndurance } from '~src/types/knife'

const wood: WoodConfig = {
  id: '2',
  name: WoodName.Olea,
  title: { PL: 'Oliwka', EN: 'Olive' },
  description: {
    PL: [
      'Drzewo oliwne uprawiane jest od 4\u00A0tysięcy lat. W\u00A0starożytności, zwłaszcza w\u00A0Azji Mniejszej , drzewa i\u00A0gaje oliwne były otoczone szczególną czcią, jednak już wtedy, ze względu na jej właściwości, oliwkę wykorzystywano również jako materiał konstrukcyjny.',
      'Współcześnie drewno oliwne jest szeroko stosowane przez artystów i\u00A0rzemieślników. Jego niepowtarzalne kontrastowe usłojenie i\u00A0ciepły, miodowy odcień sprawia, że przedmioty wykonane z\u00A0oliwki cieszą się dużą popularnością.',
      'Drewno oliwne ma wysoką gęstość, ale jest bardzo niejednorodne. Nieregularne słoje często prowadzą do powstawania pęknięć, co utrudnia pracę z\u00A0tym gatunkiem. Niemniej piękne usłojenie i\u00A0przyjemny zapach tego drewna rekompensują dodatkowe trudności.',
    ],
    EN: [
      'Olive wood has been grown for 4\u00A0thousand years. In the ancient times, especially in\u00A0Asia Minor, olive trees and groves were especially taken care of, and even then, because of its properties, olive wood was also used as a construction material.',
      'Modern olive wood is widely used by artists and artisans. Its unique contrasting grains and warm, honey-like shade make items made of olive wood very popular.',
      'Olive wood is characterized by high density but it is also quite heterogeneous. Although the irregular grains often lead to cracks, which makes it hard to work with this species of wood, the beautiful patterns of grains and pleasant smell make up for additional trouble.',
    ],
  },
  price: {
    PL: '+ 50 zł',
    EN: '+ 15 €',
  },
  priceRow: {
    PL: 50,
    EN: 15,
  },
  care: {
    PL:
      'Odpowiednio zakonserwowana oliwka nadaje się na oprawy narzędzi kuchennych. Ale żeby zachować ją w\u00A0dobrej kondycji i\u00A0wyglądzie, trzeba poświęcić jej odrobinę uwagi. W\u00A0procesie produkcyjnym oliwkę poddajemy impregnacji ciśnieniowej, co zwiększa jej odporność na działanie wody, należy jednak unikać niepotrzebnego kontaktu z\u00A0wilgocią. Rękojeści z\u00A0drewna oliwnego dobrze jest regularnie nacierać olejem dla wzmocnienia warstwy chroniącej drewno.',
    EN:
      'Properly maintained, olive wood is great for kitchen tools frames. But in\u00A0order to keep it in\u00A0good shape, it needs a bit of attention. In the production process, olive wood undergoes pressure impregnation, which increases its water durability, but it is better to avoid unnecessary contact with humidity. Handles made of olive wood should be regularly rubbed with oil to strengthen the protective layer.',
  },
  oilCare: {
    PL: 'min. raz na miesiąc.',
    EN: 'at least once a month.',
  },
  endurance: MaterialEndurance.three,
  weight: {
    PL: 'lekkie',
    EN: 'light',
  },
  img: 'olea',
}

export default wood
