import { WoodConfig, WoodName, MaterialEndurance } from '~src/types/knife'

const wood: WoodConfig = {
  id: '1',
  name: WoodName.Ironwood,
  title: { PL: 'Desert Ironwood', EN: 'Desert Ironwood' },
  description: {
    PL: [
      'Pustynne Drzewo Żelazne rośnie tylko w\u00A0jednym miejscu na Ziemi - na pustyni Sonora (część Arizony i\u00A0Kalifornii oraz część Meksyku). Jest ono prawnie chronione, jednak dozwolone jest zbieranie obumarłych gałęzi i\u00A0pni, które są źródłem jednego z\u00A0najcenniejszych materiałów wykorzystywanych na  oprawy wysokiej jakości narzędzi i\u00A0do budowy instrumentów muzycznych.',
      'Ironwood to jedno z\u00A0najpiękniejszych, najcięższych (tonie w\u00A0wodzie!), najtwardszych i\u00A0najbardziej odpornych na działanie wody drewien, jakie stworzyła natura. Ze względu na dużą gęstość, ironwood nie jest łatwe w\u00A0obróbce, ale czas poświęcony na jego wykończenie rekompensowany jest pięknym rysunkiem słojów i\u00A0niespotykaną trwałością.',
    ],
    EN: [
      'Desert Ironwood grows only in\u00A0one place in\u00A0the world – the Sonora desert (partly in\u00A0Arizona and California, partly in\u00A0Mexico). It is protected by law, but collecting dead branches and stems is allowed. These are the source of one of the most precious materials used to make high quality tools and music instruments.',
      'Ironwood is one of the most beautiful, heavy (it drowns in\u00A0water!), hardest and most water- resistant wood types ever created by nature. Because of its high density, ironwood is not easy to process, but the time you put in\u00A0finishing it will be rewarded with a beautiful exposition of grain and exceptional durability.',
    ],
  },
  price: {
    PL: '+ 150 zł',
    EN: '+ 40 €',
  },
  priceRow: {
    PL: 150,
    EN: 40,
  },
  care: {
    PL:
      'Ironwood jest naturalnie oleistym gatunkiem o zwartej strukturze, dzięki temu nie trzeba go dodatkowo konserwować. Niemniej warto co pewien czas przetrzeć rękojeści olejem ( np. tungowym, lnianym ) dla wydobycia głębszych kontrastów i\u00A0dodatkowej ochrony przed wodą.',
    EN:
      'Ironwood is a naturally oily species with a tight structure, so it does not need any additional maintenance. It is, however, worth to wipe it with linseed or tung oil every now and then to bring out deeper contrasts and provide additional protection from water.',
  },
  oilCare: {
    PL: 'wg. Uznania.',
    EN: 'as needed',
  },
  endurance: MaterialEndurance.five,
  weight: {
    PL: 'bardzo ciężkie',
    EN: 'very heavy',
  },
  img: 'ironwood',
}

export default wood
