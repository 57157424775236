import {
  MetalConfig,
  MetalName,
  SteelStainless,
  MaterialEndurance,
  EdgeRetention,
} from '~src/types/knife'

const knife: MetalConfig = {
  id: '6',
  name: MetalName.M390,
  title: { PL: 'stal M390', EN: 'M390 steel' },
  description: {
    PL: [
      'M390\u00A0z serii MICROCLEAN to osiągnięcie producentów stali\u00A0z Autriackiej huty Bohler Edelstahl\u00A0z Kapfenberga. M390 jest tzw. stalą proszkową - powstaje\u00A0w skomplikowanym technologicznie dwuetapowym procesie. W pierwszej fazie wytwarza się stalowy ‘pył’, który następnie jest zgrzewany\u00A0w celu uzyskania litych bloków materiału. Ze względu\u00A0na wymagający proces, stale powstające\u00A0w technologii proszków są znacznie droższe\u00A0od gatunków wytwarzanych konwencjonalnie. Dzięki nowoczesnemu procesowi produkcji M390 łączy\u00A0w sobie bardzo wysoką twardość, trudnościeralność\u00A0i odporność na korozję,\u00A0z jednoczesnym zachowaniem bardzo drobnego ‘ziarna’, odpowiedzialnego za wytrzymałość na złamania\u00A0i wykruszanie krawędzi. W efekcie daje to stal na ostrza z najwyższej półki.',
      'Ze stali proszkowych wykonujemy tylko ostrza\u00A0do noży, które są używane najczęściej - noże szefa kuchni 210\u00A0i 240mm oraz Santoku.',
    ],
    EN: [
      'M390 MICROCLEAN series is\u00A0an achievement of Austrian steel manufacturers from the Bohler Edelstahl facility located\u00A0in Kapfenberg. It is\u00A0a so-called powder steel and\u00A0it is created in a complicated two-step process. First phase leads to creation of\u00A0a fine steel powder, which is then hot welded\u00A0in special high pressure furnaces to create solid steel cylinders. The demanding technology supporting powder metallurgy steels production\u00A0is much more expensive than with traditional steel grades. M390 combines very high abrasion resistance, very high hardness and resistance to corrosion. It has\u00A0a very fine grain (size of crystal particles and carbides in the steel structure) responsible for withstanding breaks and chipping of the edge. The result is blade steel of the highest quality.',
      'Currently we use Powder Steel alloys only for production\u00A0of the most heavily used types of blades - chefs knives 210 and 240mm and Santoku blades.',
    ],
  },
  price: {
    PL: '+ 350 zł',
    EN: '+ 90 €',
  },
  priceRow: {
    PL: 350,
    EN: 90,
  },
  img: 'M398',
  stainless: SteelStainless.five,
  sharpening: {
    PL: 'trudne',
    EN: 'hard',
  },
  hardness: MaterialEndurance.four,
  edgeRetention: EdgeRetention.four,
}

export default knife
