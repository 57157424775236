import {
  MetalConfig,
  MetalName,
  SteelStainless,
  MaterialEndurance,
  EdgeRetention,
} from '~src/types/knife'

const knife: MetalConfig = {
  id: '5',
  name: MetalName.SanMai,
  title: { PL: 'SanMai KŁOSY', EN: 'SanMai KLOSY' },
  description: {
    PL: [
      'To najbardziej zaawansowany stopowo\u00A0i konstrukcyjnie materiał\u00A0z naszej oferty - coś dla poszukiwaczy szczytów możliwości współczesnych stali.\u00A0Do wykonania głowni tych noży,\u00A0we współpracy\u00A0ze specjalistami zgrzewania stali, dobraliśmy zestaw dwóch gatunków, które pozwalają połączyć plusy nierdzewności\u00A0i niespotykanej twardości krawędzi tnącej. Ostrze składa się\u00A0z supertwardego rdzenia,\u00A0do którego dogrzane są boki\u00A0ze stali nierdzewnej. Dzięki takiej warstwowej konstrukcji\u00A0na płazie noża pojawia się charakterystyczna granica odcinająca kontrastową linią rdzeń\u00A0i warstwy boczne.\u00A0Do wykonania rdzenia naszych laminatów używamy ultrawysokostopowej stali proszkowej opartej\u00A0na węglikach Wanadu. większość powierzchni ostrza okrywają warstwy nierdzewne',
      'W tych ostrzach łączymy szczytowe zdobycze współczesnej metalurgii\u00A0z wiedzą kowalską\u00A0i ręczną pracą, gwarantującą najwyższą precyzję\u00A0i jakość wykonania.',
      'W opcji SanMai wykonujemy tylko duże ostrza uniwersalnego zastosowania: Noże szefa kuchni 210\u00A0i 240 oraz Santoku. Zwykle przygotowujemy jedną\u00A0lub dwie serie laminatów rocznie. Odezwij\u00A0się do nas żeby potwierdzić aktualną dostępność konkretnego typu ostrza.',
    ],
    EN: [
      'SanMai KŁOSY - It\u00A0is our most advanced blade material, both considering its alloy composition and its laminated structure. If you are looking for the ‘state of art’ capabilities\u00A0of modern high abrasion resistant tool steels,\u00A0it should\u00A0be your steel of choice. Working together with steel forge-welding specialists\u00A0we developed\u00A0a set of steel grades which allow\u00A0us to combine ultimate edge retention\u00A0of the core layer with stainlessness\u00A0of side flats. This 3-layer structure brings to the blade\u00A0a characteristic look\u00A0-\u00A0a thin line of diffusion stripes marks the transition between core and side layers. To manufacture our SanMai material\u00A0we use an ultra-high-alloyed powder tool steel based\u00A0on Vanadium carbides. Whereas most\u00A0of the blade surface\u00A0is kept covered\u00A0by stainless layers.',
      'In our SanMai we combine cutting-edge discoveries\u00A0of modern metallurgy with traditional bladesmith knowledge\u00A0to bring the best quality and precision.',
      'KŁOSY SanMai\u00A0is available only for big blades which\u00A0are used most often, such as Chefs’ knives 210-240mm and Santoku blades. We usually make one\u00A0or two series\u00A0of KŁOSY SanMai laminates\u00A0a year - call\u00A0us to check the actual availability of specific blades.',
    ],
  },
  price: {
    PL: '+ 1200 zł',
    EN: '+ 260 €',
  },
  priceRow: {
    PL: 1200,
    EN: 260,
  },
  img: 'sanmai',
  stainless: SteelStainless.five,
  coreStainless: SteelStainless.one,
  sharpening: {
    PL: 'bardzo trudne',
    EN: 'very hard',
  },
  hardness: MaterialEndurance.five,
  edgeRetention: EdgeRetention.five,
}

export default knife
