import { WoodConfig, WoodName, MaterialEndurance } from '~src/types/knife'

const wood: WoodConfig = {
  id: '6',
  name: WoodName.Snakewood,
  title: { PL: 'Drewno wężowe', EN: 'Snakewood' },
  description: {
    PL: [
      'Drewno wężowe zawdzięcza swoją nazwę bardzo charakterystycznym plamkom, występującym\u00A0w części przekroju pnia. Kontrastowe centki układają się\u00A0we wzory przypominające skórę węża. Jest to jeden\u00A0z najtwardszych\u00A0i najcięższych gatunków rosnących na Ziemi. Snakewood to nieduże drzewo (ok 20m wys.), występujące naturalnie\u00A0w lasach wschodnich wybrzeży Ameryki Południowej. Charakterystyczny prążkowany rysunek, który jest szczególnie ceniony\u00A0w rzemiośle\u00A0i lutnictwie występuje tylko\u00A0w części przekroju pnia, przez\u00A0co nie da się uzyskać dużych wzorzystych elementów. Połączenie świetnych właściwości fizycznych, pięknego usłojenia\u00A0i ograniczonej dostępności powoduje,\u00A0że Snakewood jest\u00A0w ścisłej czołówce najrzadziej spotykanych\u00A0i najdroższych drewien egzotycznych.',
    ],
    EN: [
      'Snakewoods’ name was derived from\u00A0a very characteristic spotted grain texture which\u00A0can be observed in middle parts\u00A0of the tree trunk. Those dark spots can bring to mind snakeskin with its camouflage texture.Snakewood\u00A0is one of the top heaviest and hardest woods on Earth. It is\u00A0a mid-sized tree reaching\u00A0up to 20m high, its main habitats are dense forests covering eastern coast\u00A0of South America. Its unique grain, which\u00A0is especially valued\u00A0by craftsmen and luthiers, is exposed only\u00A0in\u00A0a part of the trunk section, which means the maximum size\u00A0of spotted parts\u00A0is very limited. Snakewood, valued for its great physical characteristics, unique texture together with limited supply,\u00A0is one of the rarest and most expensive exotic woods found\u00A0in quality craft.',
    ],
  },
  price: {
    PL: '+ 250 zł',
    EN: '+ 55 €',
  },
  priceRow: {
    PL: 250,
    EN: 55,
  },
  care: {
    PL:
      'Snakewood jest naturalnie oleistym gatunkiem\u00A0o zwartej strukturze, dzięki temu nie trzeba\u00A0go dodatkowo konserwować. Niemniej warto co pewien czas przetrzeć rękojeści olejem (np. tungowym, lnianym) dla wydobycia głębszych kontrastów\u00A0i dodatkowej ochrony przed wodą.',
    EN:
      'Snakewood is a naturally oily species with a tight structure, so it does not need any additional maintenance. It is, however, worth to wipe it with linseed or tung oil every now and then to bring out deeper contrasts and provide additional protection from water.',
  },
  oilCare: {
    PL: 'wg. Uznania',
    EN: 'as needed',
  },
  endurance: MaterialEndurance.five,
  weight: {
    PL: 'bardzo ciężkie',
    EN: 'very heavy',
  },
  img: 'snakewood',
}

export default wood
