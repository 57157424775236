import { WoodConfig, WoodName, MaterialEndurance } from '~src/types/knife'

const wood: WoodConfig = {
  id: '5',
  name: WoodName.Paintedwood,
  title: { PL: 'Drewno barwione', EN: 'Stained-stabilised wood' },
  description: {
    PL: [
      'Drewno barwione powstaje w\u00A0procesie polegającym na przesyceniu porowatej struktury drewna żywicami o małej lepkości. Do żywic dodajemy specjalne barwniki. Proces przesycania można podzielić na etapy i\u00A0w\u00A0bloczkach łączyć kilka kolorów żywic. Sposób , w\u00A0jaki barwniki rozejdą się w\u00A0drewnie jest uzależniony od ułożenia sęków, różnic w\u00A0gęstości i\u00A0usłojenia. Efekt końcowy jest zawsze niespodzianką.',
      'Do barwienia używamy głównie klonu, brzozy i\u00A0wierzby. Najczęściej na rękojeści wykorzystuje się tzw. czeczoty, czyli części pni o najciekawszym rysunku - pełne sęków, o szczególnie poskręcanych słojach.',
      'Jeśli w\u00A0zamówieniu wybierzesz drewno barwione, prześlemy do Ciebie zdjęcia obecnie dostępnych bloczków.',
    ],
    EN: [
      'Stabilised wood is created in\u00A0the process of oversaturating the porous structure of wood with low-viscosity resins. We add special coloring matters to the resins. The oversaturation process can be divided into stages and combine a few resin colors in\u00A0blocks. The way the coloring matters dissolve in\u00A0the wood depends on the pattern of the knots, wood density and grains. The final effect is always a surprise.',
      'We mainly colorize maple, birch and willow. For handles, we mostly use the parts of trunk with the most interesting patterns – full of knots and with the most twisted grains.',
    ],
  },
  price: {
    PL: '+ 100 zł',
    EN: '+ 30 €',
  },
  priceRow: {
    PL: 100,
    EN: 30,
  },
  care: {
    PL:
      'Drewna stabilizowane wykazują dobrą odporność na wilgoć, jednak żadna impregnacja nie chroni drewna całkowicie. Warto co pewien czas przetrzeć rękojeści olejem (np. tungowym, lnianym) dla wydobycia głębszych kontrastów i\u00A0dodatkowej ochrony przed wodą.',
    EN:
      'Stabilized wood shows high resistance to water, but no impregnation protects the wood completely. It is worth to wipe the handle with linseed or tung oil every now and then to bring out deeper contrasts and provide additional protection from water.',
  },
  oilCare: {
    PL: 'co 1-2 miesiące.',
    EN: 'every 1-2 months.',
  },
  endurance: MaterialEndurance.four,
  weight: {
    PL: 'średnio ciężkie',
    EN: 'middle',
  },
  img: 'painted',
}

export default wood
