import { WoodConfig, WoodName, MaterialEndurance } from '~src/types/knife'

const wood: WoodConfig = {
  id: '4',
  name: WoodName.Juglans,
  title: { PL: 'Orzech włoski', EN: 'Walnut' },
  description: {
    PL: [
      'Drewno orzecha włoskiego od dawna było cenione jako materiał stolarski. Do dziś używa się go w\u00A0rzemiośle np. na oprawy strzelb i\u00A0pistoletów. Do naszych noży stosujemy wyłącznie orzech w\u00A0wersji stabilizowanej ciśnieniowo (czyli przesyconej żywicami). Wynika to z\u00A0jego niewystarczającej naturalnej odporności na wilgoć, która w\u00A0środowisku kuchennym jest kluczowa. Po stabilizacji  drewno jest znacznie odporniejsze na działanie wody.  Okładziny rękojeści przygotowujemy z\u00A0orzecha ciętego w\u00A0poprzek włókien, co daje znacznie głębszy - brązowo czekoladowy  odcień, z\u00A0charakterystycznymi ciemniejszymi odbarwieniami i\u00A0jaśniejszymi przebłyskami sęków.',
    ],
    EN: [
      'Walnut tree has been valued as a carpentry material for a long time. Even today it is still used in\u00A0crafts as stocks for shotguns or handguns. For our knives we use only pressure- stabilized (saturated with resins) walnut wood. It is because of walnut’s insufficient natural resistance to water, which is key in\u00A0kitchen environment. After stabilizing, the wood is much more resistant to water. Cladding of the handle is made of wood cut crosswise the fibers to bring out much deeper, chocolate-brown shade with distinctive darker discolorations and fairer glimmers of knots.',
    ],
  },
  price: {
    PL: '',
    EN: '',
  },
  priceRow: {
    PL: 0,
    EN: 0,
  },
  care: {
    PL:
      'Drewna stabilizowane wykazują dobrą odporność na wilgoć, jednak żadna impregnacja nie chroni drewna całkowicie. Warto co pewien czas przetrzeć rękojeści olejem (np. tungowym, lnianym) dla wydobycia głębszych kontrastów i\u00A0dodatkowej ochrony przed wodą.',
    EN:
      'Stabilized wood shows high resistance to water, but no impregnation protects the wood completely. It is worth to wipe the handle with linseed or tung oil every now and then to bring out deeper contrasts and provide additional protection from water.',
  },
  oilCare: {
    PL: 'co 1-2 miesiące.',
    EN: 'every 1-2 months.',
  },
  endurance: MaterialEndurance.four,
  weight: {
    PL: 'średnio ciężkie',
    EN: 'middle',
  },
  img: 'juglans',
}

export default wood
